import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  routes: [
    {
      path: "/",
      //路由到的地址(自定义)
      name: "HomeIndex",
      //组件名称
      component: () => import("../views/home/"),
      meta: {
        show: true,
        page: "home",
        title: "听泉鉴宝客服系统",
        content: {
          keywords: "",
          description: "听泉鉴宝客服系统",
        },
      },
      //引入组件，HomeComponent.vue所在路径
      //HomeComponent.vue是需要路由的vue组件
    },
  ],
  history: createWebHistory(),
});

router.beforeEach((to, from, next) => {
  //    路由发生变化改变description和keyword
  if (to.meta.content) {
    const head = document.getElementsByTagName("head");
    const meta = document.createElement("meta");
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", to.meta.content.keywords);
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", to.meta.content.description);
    meta.content = to.meta.content;
    head[0].appendChild(meta);
  }
  // 路由发生变化修改页面title
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;

<template>
  <div>
    <!-- <Header  :page="$route.meta.page" /> -->
    <!-- <router-link to="/"> 回到首页 </router-link>
    <router-link to="/customer"> 去聊天 </router-link> -->

    <router-view></router-view>

    <!-- <Footer /> -->
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

// import Header from "@/components/page/header.vue";

// import Footer from "@/components/page/footer.vue";

@Options({
  components: {
    // Header,
    // Footer,
  },
})
export default class App extends Vue {}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

</style>

export const wordsList = [
  {
    value: '您好，您是在哪里下单的，APP是原路退回的；如果是小程序下单，退到您APP钱包，您申请提现即可',
  },
  {
    value: '您好，由于您拍摄的图片不清晰，鉴定老师无法根据图片进行判定，您可以补清晰图再次提交即可',
  },
  {
    value: '您好，这些不适合鉴定',
  },

  {
    value: '您好，因为商品太火爆，没有库存了，您这边可以去官方直播间购买哈',
  },
  {
    value: '您好，请添加一下我们这边鉴定老师的微信Tingquan-002',
  },
  {
    value: '您好，如果需要买货请添加微信17788567145',
  },
  {
    value: '您好，实在不好意思，让您久等了，由于近期鉴定数量激增，我们的鉴定老师正在全力应对，可能无法及时完成所有鉴定，敬请各位耐心等待，感谢您的理解与支持，听泉团队祝您生活偷快！',
  },
  {
    value: '您好，我们软件的商城在逐渐上货，请及时关注商城信息。',
  },
  {
    value: '您好，稍等一下，您的问题已经反馈，后台的技术人员正在全力排查中，感谢您的理解与支持',
  },
  {
    value: '您好，App请更至最新版本即可正常使用（操作流程：我的鉴定——点击“已完结”界面，即可查看鉴定结果）',
  },
  {
    value: '您好，这个是绝对不会的，因为我们鉴定的特殊性，后台都是和公安联网的，安全问题您完全可以放心',
  },
  {
    value: '您好，目前我们的商品正在挑选中，我们需要保质保量的把商品交到您的手中，商品上架之后会第一时间通知的，请您耐心等待，感谢您的理解与支持',
  },
  {
    value: '您好，我们这个入口暂未开放，后续开放的话会第一时间通知的，您这边可以关注下',
  },

];


const Words = {
  "请对本次服务进行评价": "Please rate this service",
  "提交评价": "Feedback",
  "常用语-快捷回复工具": "Common Expressions - Quick Reply Tool",
  "使用": "Build with",
  "自定义消息": "Custom message",
  "搭建": "",
  "您好，实在不好意思，让您久等了，由于近期鉴定数量激增，我们的鉴定老师正在全力应对，可能无法及时完成所有鉴定，敬请各位耐心等待，感谢您的理解与支持，听泉团队祝您生活偷快！": "Hello, I'm really sorry to have kept you waiting for so long. Due to the recent surge in the number of appraisals, our appraisal teachers are working hard to cope with it, and may not be able to complete all appraisals in time. Please wait patiently. Thank you for your understanding and support. The Tingquan team wishes you a happy life!",
  "您好，我们软件的商城在逐渐上货，请及时关注商城信息。": "Hello, the mall of our software is gradually being stocked, please pay attention to the mall information in time.",
  "您好，稍等一下，您的问题已经反馈，后台的技术人员正在全力排查中，感谢您的理解与支持": "Hello, wait a moment, your problem has been reported, and the technical staff in the background are doing their best to investigate. Thank you for your understanding and support",
  "您好，App请更至最新版本即可正常使用（操作流程：我的鉴定——点击“已完结”界面，即可查看鉴定结果）": "Hello, please update the App to the latest version for normal use (operation process: My Appraisal-Click on the 'Completed' interface to view the appraisal results) ",
  "您好，这个是绝对不会的，因为我们鉴定的特殊性，后台都是和公安联网的，安全问题您完全可以放心": "Hello, this will never happen, because of the particularity of our appraisal, the background is connected to the public security network, and you can rest assured about security issues",
  "您好，目前我们的商品正在挑选中，我们需要保质保量的把商品交到您的手中，商品上架之后会第一时间通知的，请您耐心等待，感谢您的理解与支持": "Hello, our products are currently being selected, we need to deliver the products to you with quality and quantity, and we will notify you as soon as the products are put on the shelves. Please wait patiently, thank you for your understanding and support",
  "您好，我们这个入口暂未开放，后续开放的话会第一时间通知的，您这边可以关注下": "Hello, our entrance is not open yet, and we will notify you as soon as possible if it is opened later. You can pay attention to it here",
  "没关系，别在意，事情过去就过去了。": "Take it easy.",
  "熬夜有什么用，又没人陪你聊天，早点休息吧。": "It's better to go to bed early.",
  "您好，如果需要买货请添加微信17788567145": "您好，如果需要买货请添加微信17788567145"
}

export default Words;

